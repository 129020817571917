.rft_filetable {
    border: none;
    position: relative;
    display: flex;
    flex-direction: column;
}

.rft_filetable .rft_table {
    flex: auto 1;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    width: 100%;
    border-collapse: collapse;
    overflow: auto;

    .rft_header {
        box-shadow: none;
        flex: 1 0 auto;
        display: flex;
        flex-direction: column;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        .rft_tr {
            border-bottom: 1px solid #e1e5eb;
            text-align: center;
            flex: 1 0 auto;
            display: inline-flex;
            .rft_th {
                background-color: #fbfbfb;
                font-weight: 400;
                padding: .75rem 1.0625rem !important;
                font-size: 13px !important;
                border: 13px !important;
            }
        }
    }

    .rft_body {
        flex: 99999 1 auto;
        display: flex;
        flex-direction: column;
        overflow: auto;
        .rft_tr {
            flex: 1 0 auto;
            display: inline-flex;
            .rft_td {
                &:first-of-type {
                    border-left: none;
                    align-items: center;
                    justify-content: center;
                }
                &:last-child {
                    border-right: none;
                }
                font-size: .8125rem;
                padding: .1375rem .8625rem;
                border-left: 1px solid #e1e5eb;
                border-bottom: 1px solid #e1e5eb;
                border-right: 1px solid #e1e5eb;
                text-align: center;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
    }
}
