.sdx-page-loader {
    position: fixed;
    left: 45%;
    top: 35%;
    width: 100%;
    height: 100%;
    z-index: 9999;
}

.sdx-align-icon {
    vertical-align: middle !important;
}

.sdx-bc-link {
    color: blue !important;
    cursor: pointer;
    &:hover {
        color: lightblue;
    }
}

.sdx-input-group-clickable {
    &:hover {
        color: red;
        cursor: pointer;
    }
}

.sdx-input-group-clickable-blue {
    &:hover {
        color: blue;
        cursor: pointer;
    }
}

.sdx-table-row-clickable {
    &:hover {
        cursor: pointer;
        background-color: #FBFBFB;
    }
}

.update-template-image {
    opacity: 0 !important;
    display: block !important;
    position: fixed !important;
    z-index: 1031;
    top: calc( 0% - ( 48px / 2) ); /* where ... is the element's height */
    right: calc( 45% - ( 380px / 2) ); /* where ... is the element's width */
    transition: .7s ease;
    backface-visibility: hidden;
    span {
        font-size: 48px !important;
    }
}

.complete {
    opacity: 1 !important;
    top: calc( 50% - ( 48px / 2) ); /* where ... is the element's height */
    right: calc( 45% - ( 380px / 2) ); /* where ... is the element's width */
}

.update-complete-text {
    font-size: 24px !important;
}

.update-template-background {
    opacity: 1;
}
.update-template-background-complete {
    opacity: 0.3 !important;
}

.dropdown-scroll {
    max-height: 40vh;
    overflow: auto;
    
}

